import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { ColDef, GridApi } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererWithImageComponent } from "src/app/shared/components/ag-grid/link-renderer-with-image/link-renderer-with-image.component";
import { QualificationChipsRendererComponent } from "src/app/shared/components/ag-grid/qualification-chips-renderer/qualification-chips-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { EmployeeService } from "src/app/shared/generated/api/employee.service";
import { ExperienceLevelService } from "src/app/shared/generated/api/experience-level.service";
import { QualificationService } from "src/app/shared/generated/api/qualification.service";
import { EmployeeQualificationSummaryDto } from "src/app/shared/generated/model/employee-qualification-summary-dto";
import { QualificationDto } from "src/app/shared/generated/model/qualification-dto";
import { UserClaimsDto } from "src/app/shared/generated/model/user-claims-dto";
import { environment } from "src/environments/environment";
import { QualificationAutoCompleteComponent } from "../../../shared/components/qualification-autocomplete/qualification-autocomplete.component";
import { TalentBridgeGridComponent } from "../../../shared/components/talentbridge-grid/talentbridge-grid.component";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: "talentbridge-employee-list",
    templateUrl: "./employee-list.component.html",
    styleUrls: ["./employee-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        QualificationAutoCompleteComponent,
        TalentBridgeGridComponent,
        MatIconModule
    ],
})
export class EmployeeListComponent implements OnInit {
    @ViewChild("QualificationAutocomplete") qualificationAutocomplete: QualificationAutoCompleteComponent

    public currentUser: UserClaimsDto;
    public rowData = [];
    public columnDefs: ColDef[];
    user: Subscription;
    getEmployeeRequest: Subscription;
    qualificationsSubscription: Subscription;
    qualifications: QualificationDto[];
    experienceLevelSubscription: Subscription;
    experienceLevels: QualificationDto[];
    gridApi: GridApi;

    filteredQualificationDtos: EmployeeQualificationSummaryDto[] = [];

    constructor(
        private employeeService: EmployeeService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private qualificationService: QualificationService,
        private experienceLevelService: ExperienceLevelService
    ) {
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.columnDefs = [
                    {
                        headerName: "Employee Name",
                        headerTooltip: "Employee Name",
                        valueGetter: function (params: any) {
                            return {
                                    LinkValue: params.data.UserID,
                                    LinkDisplay: params.data.FullName,
                                    ImageSrc: `${environment.mainAppApiUrl}/users/${params.data.UserID}/photoThumbnail?uid=${result.UserGuid}&secure=${result.FileAccessToken}`
                                };
                        },
                        cellRendererSelector: (params) => {
                            return {
                                    component: LinkRendererWithImageComponent,
                                    params: { inRouterLink: "/employees/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.data.FullName;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
    
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "FullName",
                    },
                    {
                        headerName: "Job Title",
                        field: "JobTitle",
                        flex: 2,
                        tooltipField: "JobTitle",
                        wrapText: true,
                    },
                    {
                        headerName: "Department",
                        field: "Department",
                        flex: 2,
                        tooltipField: "Department",
                        wrapText: true,
                    },
                    {
                        headerName: "Office Location",
                        field: "OfficeLocation",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "OfficeLocation",
                        },
                        tooltipField: "OfficeLocation",
                        wrapText: true,
                    }, 
                    {
                        headerName: "Qualifications Count",
                        field: "Qualifications.length",
                        flex: 2,
                        tooltipField: "Qualifications.length",
                        wrapText: true,
                    },
                    {
                        headerName: "Qualifications",
                        headerTooltip: "Qualifications",
                        field: "$FilteredQuals",
                        cellRenderer: QualificationChipsRendererComponent,
                        cellRendererParams: function (params: any) {
                            return {
                                Qualifications: params.data.$FilteredQuals
                            };
                        },
                        valueGetter: function (params: any) {
                            if(params.data.$FilteredQuals != null){
                                return {
                                    DownloadDisplay: params.data.$FilteredQuals?.map(x => x.Qualification.Name).join(", "),
                                };
                            }
                            else{
                                return {
                                    DownloadDisplay: "",
                                };
                            }
                        },
                        filter: null,
                        flex: 4,
                        autoHeight: true,
                    },          
                ];
                this.cdr.markForCheck();
            });

        
        this.qualificationsSubscription = this.qualificationService.qualificationsGet().subscribe((qualifications) => {
            this.qualifications = qualifications;
            this.cdr.markForCheck();
        });

        this.experienceLevelSubscription = this.experienceLevelService.experienceLevelsGet().subscribe((expLevels) => {
            this.experienceLevels = [{ExperienceLevelID: null, DisplayName: "Any", Name: "Any"}, ...expLevels];

            this.cdr.markForCheck();
        });
}

    ngOnDestroy(): void {
        this.cdr.detach();
        this.user?.unsubscribe();
        this.getEmployeeRequest?.unsubscribe();
        this.qualificationsSubscription?.unsubscribe();
        this.experienceLevelSubscription?.unsubscribe();
    }

    onEmployeesGridReady(gridEvent) {
        this.gridApi = gridEvent.api;
        this.gridApi.showLoadingOverlay();

        this.gridApi.setGridOption("isExternalFilterPresent", this.isExternalFilterPresent.bind(this));
        this.gridApi.setGridOption("doesExternalFilterPass", this.doesExternalFilterPass.bind(this));
        
        this.getEmployeeRequest = this.employeeService
            .employeesGet()
            .subscribe((results) => {
                this.rowData = results.filter((employee) => {
                    return employee.IsActive;
                }).map((employee) => { 
                    return {
                        ...employee,
                        $FilteredQuals: employee.Qualifications
                    };
                });
                
                this.gridApi.hideOverlay();
                this.updateColumnVisiblity();
                this.cdr.markForCheck();
            });
    }

    isExternalFilterPresent(): boolean {
        return this.filteredQualificationDtos && this.filteredQualificationDtos.length > 0;
    }

    doesExternalFilterPass(node: any): boolean {
        if (node.data) {
            if (this.filteredQualificationDtos && this.filteredQualificationDtos.length > 0) {
                return this.filteredQualificationDtos.every((q) => {
                    var qualMatches = node.data.Qualifications.some((eq) => eq.Qualification.QualificationID === q.Qualification.QualificationID && (q.ExperienceLevel?.ExperienceLevelID == null || eq.ExperienceLevel.ExperienceLevelID === q.ExperienceLevel.ExperienceLevelID));
                    
                    return qualMatches;
                });
            }
        }
        return true;
    }
    
    qualificationAdded(event: any) {
        this.filteredQualificationDtos.push(event);
        this.filterQualificationColumn();
        this.updateColumnVisiblity();
        this.cdr.markForCheck();
    }

    qualificationRemoved(event: any) {
        this.filteredQualificationDtos = this.filteredQualificationDtos.filter(i => i !== event);
        this.filterQualificationColumn();
        this.updateColumnVisiblity();
        this.cdr.markForCheck();
    }

    updateColumnVisiblity() {        
        this.gridApi.setColumnsVisible(["Qualifications.length"], false);
        this.gridApi.setColumnsVisible(["$FilteredQuals"], false);

        if (this.filteredQualificationDtos.length > 0) {
            this.gridApi.setColumnsVisible(["$FilteredQuals"], true);
        }
        else {
            this.gridApi.setColumnsVisible(["Qualifications.length"], true);
        }
    }

    filterQualificationColumn() {
        this.rowData.forEach((employee) => {
            employee.$FilteredQuals = employee.Qualifications.filter((q) => {
                return this.filteredQualificationDtos.some((eq) => eq.Qualification.QualificationID === q.Qualification.QualificationID);
            });
        }); 
    }

    clearFilters() {
        this.gridApi.setFilterModel(null);
        this.qualificationAutocomplete.clear();
        this.filteredQualificationDtos = [];
        this.updateColumnVisiblity();
        this.cdr.markForCheck();
    }

    isFilterActive() {
        if (this.gridApi) {
            return this.gridApi.isAnyFilterPresent() || this.isExternalFilterPresent();
        }
        return this.isExternalFilterPresent();
    }
    
}
