<div class="list-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title">Service Lines</h1>
        @if (canCreate) {
            <esa-material-button
                label="Add Service Line"
                icon="add"
                type="primary"
                class="secondary-outline-button"
                (click)="navigateToServiceLineCreatePage()"
            >
            </esa-material-button>
        }
    </div>

    <div class="table-responsive">
        <talentbridge-grid
            #serviceLinesGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="serviceLines"
            recordNamePlural="Service Lines"
            (gridReady)="onServiceLinesGridReady($event)"
        >
        </talentbridge-grid>
    </div>
</div>
