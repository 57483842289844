<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Projects</h1>
            @if (canCreateProject) {
                <esa-material-button
                    label="Add Project"
                    icon="add"
                    type="primary"
                    class="secondary-outline-button"
                    (click)="navigateToProjectCreatePage()"
                >
                </esa-material-button>
            }
        </div>
    </div>
    <mat-accordion>
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title> Projects Map </mat-panel-title>
            </mat-expansion-panel-header>

            <map #MapComponent (onMapLoad)="mapInit($event)">
                @if (map) {
                    @for (p of filteredProjectsWithGeoData; track $index) {
                        <map-marker
                            #mapMarker
                            [latitude]="p.Latitude"
                            [longitude]="p.Longitude"
                            [label]="p.Name"
                            [map]="map"
                            (onClick)="onMarkerClick($event, p)"
                        >
                        </map-marker>
                    }
                }
            </map>
        </mat-expansion-panel>
    </mat-accordion>

    <div>
        <div class="table-responsive">
            <talentbridge-grid
                #ProjectsGrid
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                downloadFileName="projects"
                recordNamePlural="Projects"
                rowSelection="single"
                [pagination]="true"
                (gridReady)="onProjectsGridReady($event)"
                (selectionChanged)="onSelectionChanged($event)"
                (filterChanged)="onFilterChanged($event)"
            >
            </talentbridge-grid>
        </div>
    </div>
</div>
