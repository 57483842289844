import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { ProjectDto } from '../shared/generated/model/project-dto';
import { ProjectUpsertDto } from '../shared/generated/model/project-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';

@Injectable({
  providedIn: 'root'
})
export class CurrentProjectService {
  currentProject$ = new BehaviorSubject<ProjectDto>(null);

  constructor(private authenticationService: AuthenticationService) {}

  setCurrentProject(project: ProjectDto): void {
      this.currentProject$.next(project);
  }

  getCurrentProject(): Observable<any> {
      return this.currentProject$.asObservable();
  }

  createProjectDto(project: ProjectDto) {
      const projectDto = new ProjectUpsertDto({
          Name: project?.Name,
          ClientID: project?.Client?.ClientID,
          DNumber: project?.DNumber,
          Status: project?.Status,
          ProjectManager: project?.ProjectManager,
          StartDate: project?.StartDate ? new Date(project.StartDate) : null,
          EndDate: project?.EndDate ? new Date(project.EndDate) : null,
          Location: project?.Location,
          Latitude: project?.Latitude,
          Longitude: project?.Longitude,
          Description: project?.Description,
          StandardDescription: project?.StandardDescription,
          OpportunityDescription: project?.OpportunityDescription
      });
      return projectDto;
  }

  canEditCurrentProject(currentUser: UserDto, project: ProjectDto): boolean {
    if (this.authenticationService.hasPermission(currentUser, PermissionEnum.ProjectRights, RightsEnum.Update)) {
      return true;
    }

    if (!currentUser || !project) {
      return false;
    }

    return project.ProjectTeamUserIDs.includes(currentUser.UserID);
  }
}
