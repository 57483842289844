import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentEmployeeService } from 'src/app/services/current-employee.service';
import { EmployeeQualificationService } from 'src/app/shared/generated/api/employee-qualification.service';
import { QualificationService } from 'src/app/shared/generated/api/qualification.service';
import { EmployeeDto } from 'src/app/shared/generated/model/employee-dto';
import { EmployeeQualificationSummaryDto } from 'src/app/shared/generated/model/employee-qualification-summary-dto';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { EmployeeQualificationUpsertDto } from 'src/app/shared/generated/model/employee-qualification-upsert-dto';
import { ExperienceLevelService } from 'src/app/shared/generated/api/experience-level.service';
import { ExperienceLevelDto } from 'src/app/shared/generated/model/experience-level-dto';
import { EmployeeService } from 'src/app/shared/generated/api/employee.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EmployeeQualificationAutoCompleteComponent } from 'src/app/shared/components/employee-qualification-autocomplete/employee-qualification-autocomplete.component';

@Component({
    selector: 'talentbridge-employee-qualifications',
    templateUrl: './employee-qualifications.component.html',
    styleUrls: ['./employee-qualifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        EsaMaterialButtonComponent,
        EmployeeQualificationAutoCompleteComponent,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MatButton    
    ]
})
export class EmployeeQualificationsComponent implements OnInit, OnDestroy {

    public employee: EmployeeDto;
    public currentUser: UserDto;
    qualificationsSubscription: Subscription;
    experienceLevelSubscription: Subscription;

    currentUserSubscription: Subscription;
    employeeQualificationsSubscription: Subscription;
    editMode: boolean = false;
    qualifications: QualificationDto[];
    employeeQualificationUpsertDtos: EmployeeQualificationUpsertDto[];
    employeeQualificationDtos: EmployeeQualificationSummaryDto[];
    experienceLevels: ExperienceLevelDto[];
    originalEmployeeQualificationUpsertDtos: EmployeeQualificationUpsertDto[];

    constructor(
        private employeeService: EmployeeService,
        private currentEmployeeService: CurrentEmployeeService,
        private authenticationService: AuthenticationService,
        private employeeQualificationService: EmployeeQualificationService,
        private qualificationService: QualificationService,
        private experienceLevelService: ExperienceLevelService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService,
    ) { }

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((user) => {
            this.currentUser = user;
            this.currentEmployeeService.currentEmployee$.subscribe((employee) => {
                this.employee = employee;

                this.refreshData();
            });
        });
    }


    refreshData() {
        if (this.employee) {
            this.employeeQualificationsSubscription = this.employeeQualificationService.employeesUserIDQualificationsGet(this.employee.UserID).subscribe((employeeQualifications) => {
                this.employeeQualificationDtos = employeeQualifications;
                this.employeeQualificationUpsertDtos = this.createEmployeeQualificationDtos(employeeQualifications);
                //create a copy of the dtos. This is used to check if the user has made any changes
                this.originalEmployeeQualificationUpsertDtos = JSON.parse(JSON.stringify(this.employeeQualificationUpsertDtos));

                this.cdr.markForCheck();
            });

            this.qualificationsSubscription = this.qualificationService.qualificationsGet().subscribe((qualifications) => {
                this.qualifications = qualifications;
                this.cdr.markForCheck();
            });

            this.experienceLevelSubscription = this.experienceLevelService.experienceLevelsGet().subscribe((expLevels) => {
                this.experienceLevels = expLevels;
                this.cdr.markForCheck();
            });
        }
    }


    ngOnDestroy(): void {
        this.cdr.detach();
        this.currentUserSubscription?.unsubscribe();
        this.employeeQualificationsSubscription?.unsubscribe();
        this.qualificationsSubscription?.unsubscribe();
    }

    createEmployeeQualificationDtos(employeeQualificationDtos: Array<EmployeeQualificationSummaryDto>): Array<EmployeeQualificationUpsertDto> {
        let returnValue: Array<EmployeeQualificationUpsertDto> = [];
        for (const employeeQualificationDto of employeeQualificationDtos) {
            const upsertDto = new EmployeeQualificationUpsertDto({
                UserID: employeeQualificationDto.UserID,
                QualificationID: employeeQualificationDto.Qualification.QualificationID,
                ExperienceLevelID: employeeQualificationDto.ExperienceLevel?.ExperienceLevelID,
            });
            returnValue.push(upsertDto);
        }
        return returnValue;
    }

    addQualification(qual: EmployeeQualificationSummaryDto): void {
        if (!qual) {
            return;
        }

        let upsertDto = new EmployeeQualificationUpsertDto({
            UserID: this.employee.UserID,
            QualificationID: qual.Qualification.QualificationID,
            ExperienceLevelID: qual.ExperienceLevel?.ExperienceLevelID
        });
        this.employeeQualificationUpsertDtos.push(upsertDto);
    }

    removeQualification(qualification: EmployeeQualificationSummaryDto): void {
        let upsertDto = this.employeeQualificationUpsertDtos.find(q => q.QualificationID === qualification.Qualification.QualificationID && q.ExperienceLevelID === qualification.ExperienceLevel?.ExperienceLevelID);

        if (qualification && upsertDto) {
            this.employeeQualificationUpsertDtos.splice(this.employeeQualificationUpsertDtos.indexOf(upsertDto), 1);
        }

        this.cdr.markForCheck();
    }

    save() {
        this.employeeQualificationService.employeesUserIDQualificationsPut(this.employee.UserID, this.employeeQualificationUpsertDtos).subscribe((_) => {
            this.employeeService.employeesUserIDGet(this.employee.UserID).subscribe((employee) => {
                this.currentEmployeeService.setCurrentEmployee(employee);
                this.editMode = false;
                this.refreshData();
                this.cdr.detectChanges();
            });
        }, (error) => {
            console.error(error);
        });
    }

    enableEditMode() {
        this.editMode = true;
        this.cdr.markForCheck();
    }

    cancelEditMode() {
        this.editMode = false;
        this.refreshData();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.originalEmployeeQualificationUpsertDtos) === JSON.stringify(this.employeeQualificationUpsertDtos);
        } else {
            return true;
        }
    }

    canEdit(employee: EmployeeDto): boolean {
        return this.currentEmployeeService.canEditCurrentEmployee(this?.currentUser, employee) && !this.editMode;
    }

    verifyQualifications() {
        this.employeeService.employeesUserIDVerifyQualificationsPut(this.currentUser.UserID).subscribe((employee) => {
            this.alertService.pushAlert(new Alert("Your qualifications were successfully verified.", AlertContext.Success), 5000);
            this.currentEmployeeService.setCurrentEmployee(employee);
        });
    }
}
