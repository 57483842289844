<div class="detail-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title">Summary</h1>

        @if (employee$ | async; as emp) {
            <div class="actions-bar">
                @if (canEdit(emp)) {
                    <esa-material-button
                        class="secondary-outline-button"
                        icon="edit"
                        label="Edit"
                        (click)="enableEditMode()"
                    >
                    </esa-material-button>
                }
            </div>
        }
    </div>

    @if (employee$ | async; as emp) {
        <talentbridge-employee-form
            #updateEmployeeForm
            [userID]="emp.UserID"
            [employee]="emp"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"
        >
        </talentbridge-employee-form>
    }
</div>
