<form class="form grid-12" #configurationForm="ngForm">
  <div class="g-col-12">
    <esa-material-ff
      name="SendReminderEmail"
      [editMode]="editMode"
      [required]="true"
      label="Send Reminder Email"
      type="checkbox"
      [(ngModel)]="adminConfigurationUpsertDto.SendReminderEmail">
    </esa-material-ff>
  </div>
  
  @if (adminConfigurationUpsertDto.SendReminderEmail) {
    <div class="g-col-6">
      <esa-material-ff
        name="ReminderEmailAgeInDays" 
        [editMode]="editMode" 
        label="Reminder Email Age (days)"
        type="text"
        [(ngModel)]="adminConfigurationUpsertDto.ReminderEmailAgeInDays">
      </esa-material-ff>
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="NumberOfReminderEmails" 
        [editMode]="editMode" 
        label="Number Of Reminder Emails"
        type="text"
        [(ngModel)]="adminConfigurationUpsertDto.NumberOfReminderEmails">
      </esa-material-ff>
    </div>
  }

  @if (editMode) {
    <div class="g-col-12 actions-bar" style="margin-top: 1rem">
      <esa-material-button
        style="margin-right: 1rem"
        type="primary"
        label="Save"
        icon="save"
        [tooltip]="!configurationForm.form.valid ? 'You are missing required fields.' : ''"
        (click)="saveForm(form)"
        [disabled]="!configurationForm.form.valid">
      </esa-material-button>
      <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"> </esa-material-button>
    </div>
  }
</form>

