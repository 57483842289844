<div class="home-view">
    <section class="bento | grid-12">
        <div class="bento__info bento__info-top">
            <div class="info-wrapper">
                <svg
                    width="81"
                    height="49"
                    viewBox="0 0 81 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21.4039 0.955719H0V22.3521C1.10379 11.021 10.0724 2.05556 21.4039 0.955719Z"
                        fill="white"
                    />
                    <path
                        d="M26.0003 48.3562H47.4023V26.956C46.3004 38.2879 37.3323 47.2552 26.0003 48.3562Z"
                        fill="white"
                    />
                    <path
                        d="M30.6864 20.4844H32.9911V1.46747H30.6864V0.95572H47.4023V6.62047H46.8794V6.22451C46.8794 3.63214 44.9724 1.46747 41.5844 1.46747H37.4344V10.1822H39.4086C41.2576 10.1822 42.2269 9.12879 42.2269 6.42249V6.10311H42.7387V15.1073H42.2269V14.4518C42.2269 12.429 41.2576 10.6939 39.4086 10.6939H37.4344V20.4844H41.5433C46.2126 20.4844 46.8943 16.6108 46.8943 14.9859V14.5937H47.4098V21.0037H30.6864V20.4844ZM49.4213 13.4563H49.9331C50.6166 16.6743 52.7794 20.9439 56.6232 20.9439C59.0736 20.9439 60.7228 19.4367 60.7228 16.9302C60.7228 10.7219 49.3634 15.1951 49.3634 6.62047C49.3634 2.86078 51.868 0.5 55.6258 0.5C58.504 0.5 59.4154 2.03525 60.7004 2.03525C61.5558 2.03525 61.6137 1.28817 61.6137 0.927704H62.1273V7.33393H61.6137C60.9021 4.31385 59.3071 1.01175 55.7173 1.01175C53.5508 1.01175 51.9222 2.46483 51.9222 4.65751C51.9222 10.1542 63.4571 5.82483 63.4571 14.2818C63.4571 18.7232 60.9507 21.4575 56.9351 21.4575C54.4585 21.4575 52.1239 19.9783 50.9846 19.9783C50.2151 19.9783 49.9312 20.49 49.9312 21.0317H49.4194L49.4213 13.4563ZM62.3178 20.4844H62.6914C63.7728 20.4004 64.2882 19.7149 64.63 18.7736L71.2305 0.5H71.6601L79.2915 20.4844H80.6848V20.9981H72.1998V20.4844H74.4747L72.3997 14.5657H66.875L65.5079 18.5812C65.3914 18.8524 65.3237 19.142 65.308 19.4367C65.308 20.3705 66.1055 20.49 66.677 20.49H67.4727V21.0037H62.3178V20.4844ZM72.17 14.0483L69.6934 6.85019H69.6373L67.1029 14.0539L72.17 14.0483Z"
                        fill="white"
                    />
                </svg>

                <p>
                    TalentBridge collects all of ESA's talent and experience in
                    one place to better connect our capabilities and streamline
                    our opportunities together.
                </p>
            </div>
        </div>

        <div class="bento__photo bento__photo-top">
            <img
                src="../assets/main/home/bento-top.jpg"
                alt="ESA Employee-Owners on survey"
            />
        </div>

        <div class="bento__info bento__info-bottom">
            <div class="info-wrapper">
                <p>
                    Find <a routerLink="/employees">people</a> to collaborate
                    with, explore past
                    <a routerLink="/projects">projects</a> and
                    <a routerLink="/clients">clients</a>, and discover our
                    wealth of <a routerLink="/qualifications">skills</a>.
                </p>
            </div>
        </div>

        <div class="bento__photo bento__photo-bottom">
            <img
                src="../assets/main/home/bento-bottom.jpg"
                alt="ESA Employee-Owner at a beach"
            />
        </div>
    </section>

    <section class="get-started">
        <div class="get-started__wrapper | centered-section">
            <h2 class="get-started__header">Get Started</h2>

            <div class="get-started__body">
                <div class="get-started__feature">
                    <div class="get-started__number"><span>1</span></div>
                    <h3 class="get-started__title">Update your profile</h3>
                    <p class="get-started__copy">
                        Add your biography and contact information.
                        <a
                            routerLink="/employees/{{
                                this.currentUser?.UserID
                            }}"
                            >Update your profile</a
                        >.
                    </p>
                </div>

                <div class="get-started__feature">
                    <div class="get-started__number"><span>2</span></div>
                    <h3 class="get-started__title">
                        Add skills and qualifications
                    </h3>
                    <p class="get-started__copy">
                        Keep your skills list up-to-date.<br />
                        <a
                            routerLink="/employees/{{
                                this.currentUser?.UserID
                            }}/qualifications"
                            >Add skills</a
                        >.
                    </p>
                </div>

                <div class="get-started__feature">
                    <div class="get-started__number"><span>3</span></div>
                    <h3 class="get-started__title">
                        Explore our talent and experience
                    </h3>
                    <p class="get-started__copy">
                        Discover <a routerLink="/employees">people</a> and
                        <a routerLink="/qualifications">skills</a> at ESA, and
                        research past <a routerLink="/projects">projects</a> and
                        <a routerLink="/clients">clients</a>.
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="stats">
        <div class="centered-section">
            <h2 class="stats__header">
                TalentBridge leverages decades of ESA data and information
            </h2>

            <div class="stats__body">
                <div class="stats__feature">
                    <p class="stats__feature-label">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >badge</mat-icon
                        >
                        <span>Employee-Owners</span>
                    </p>
                    <h3 class="stats__feature-value">{{ metadata?.EmployeeCount | number: '1.0-0' }}</h3>
                </div>

                <div class="stats__feature">
                    <p class="stats__feature-label">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >military_tech</mat-icon
                        >
                        <span>Skills & Qualifications</span>
                    </p>
                    <h3 class="stats__feature-value">{{ metadata?.QualificationCount | number: '1.0-0' }}</h3>
                </div>

                <div class="stats__feature">
                    <p class="stats__feature-label">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >eco</mat-icon
                        >
                        <span>Projects</span>
                    </p>
                    <h3 class="stats__feature-value">{{ metadata?.ProjectCount | number: '1.0-0' }}</h3>
                </div>

                <div class="stats__feature">
                    <p class="stats__feature-label">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >account_balance</mat-icon
                        >
                        <span>Clients</span>
                    </p>
                    <h3 class="stats__feature-value">{{ metadata?.ClientCount | number: '1.0-0' }}</h3>
                </div>
            </div>
        </div>
    </section>

    <section class="features">
        <div class="features__wrapper | centered-section">
            <h2 class="features__header">What can we do with TalentBridge?</h2>

            <div class="features__body">
                <div class="feature">
                    <div class="feature__photo">
                        <img
                            src="../assets/main/home/features-1.jpg"
                            alt="ESA Employee-Owners"
                        />
                    </div>

                    <div class="feature__info">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >badge</mat-icon
                        >

                        <h3 class="feature__headline">
                            Keep staff skills and qualifications updated
                        </h3>

                        <div class="feature__copy">
                            <p>
                                Represent your professional capabilities with
                                the most up-to-date information to give our
                                project managers and marketers the fullest view
                                of our team's experience and credentials.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="feature | even">
                    <div class="feature__photo">
                        <img
                            src="../assets/main/home/features-2.jpg"
                            alt="ESA Employee-Owners"
                        />
                    </div>

                    <div class="feature__info">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >military_tech</mat-icon
                        >

                        <h3 class="feature__headline">
                            Discover Employee-Owners who fill a need in an
                            upcoming opportunity
                        </h3>

                        <div class="feature__copy">
                            <p>
                                Search our database of skills and experience
                                that would be the right fit for an upcoming
                                pursuit. Find talent you didn't know was here at
                                ESA.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="feature">
                    <div class="feature__photo">
                        <img
                            src="../assets/main/home/features-3.jpg"
                            alt="ESA Employee-Owners"
                        />
                    </div>

                    <div class="feature__info">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >eco</mat-icon
                        >

                        <h3 class="feature__headline">
                            Research related past projects similar to an
                            upcoming pursuit
                        </h3>

                        <div class="feature__copy">
                            <p>
                                Find successfully won and delivered projects by
                                ESA that are similar to your opportunity.
                                Discover who worked on the project, view the
                                winning proposal, and understand the ingredients
                                of its success.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="feature | even">
                    <div class="feature__photo">
                        <img
                            src="../assets/main/home/features-4.jpg"
                            alt="ESA Employee-Owners"
                        />
                    </div>

                    <div class="feature__info">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >account_balance</mat-icon
                        >

                        <h3 class="feature__headline">
                            Explore our history with an existing client
                        </h3>

                        <div class="feature__copy">
                            <p>
                                Review past projects with our clients to
                                understand what projects we have worked on and
                                who at ESA has experience navigating our
                                relationship with them.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="coming-soon">
        <div class="coming-soon__wrapper | centered-section">
            <div class="coming-soon__photo">
                <img
                    src="../assets/main/home/coming-soon.jpg"
                    alt="Solar Panels"
                />
            </div>

            <div class="coming-soon__info">
                <h4 class="coming-soon__sub-header">Coming Soon</h4>
                <h3 class="coming-soon__headline">
                    <img
                        src="../assets/main/home/esa-intelligence.png"
                        alt="ESA Intelligence"
                    />
                    ESA Intelligence
                </h3>

                <div class="feature__copy">
                    <p>
                        Leverage all of ESA's talent and experience to create
                        winning proposals that identify the best skilled and
                        most diverse project teams - powered by AI.
                    </p>
                </div>
            </div>
        </div>
    </section>
</div>
