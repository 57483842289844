<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title has-icon">
                <mat-icon fontSet="material-icons-outlined">badge</mat-icon>
                Employee-Owners
            </h1>
        </div>
    </div>
    @if (qualifications && experienceLevels) {
        <h3>Filter by Qualification</h3>
        <talentbridge-qualification-autocomplete
            #QualificationAutocomplete
            [editMode]="true"
            [qualifications]="qualifications"
            [experienceLevels]="experienceLevels"
            (qualificationAdded)="qualificationAdded($event)"
            (qualificationRemoved)="qualificationRemoved($event)"
        >
        </talentbridge-qualification-autocomplete>
    }

    <div>
        <div class="table-responsive">
            <!-- Pagination doesn't work well with External filters in this component. -->
            <talentbridge-grid
                #EmployeesGrid
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                downloadFileName="employees"
                recordNamePlural="Employees"
                [pagination]="false"
                [isExternalFilterPresent]="isExternalFilterPresent.bind(this)"
                [doesExternalFilterPass]="doesExternalFilterPass.bind(this)"
                (gridReady)="onEmployeesGridReady($event)"
                (filterCleared)="clearFilters()"
            >
            </talentbridge-grid>
        </div>
    </div>
</div>
