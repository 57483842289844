<div class="list-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title">Skills & Qualifications</h1>

        <div class="actions-bar flex-between">
            @if (canCreate) {
                <esa-material-button
                    class="secondary-outline-button"
                    label="Qualification"
                    icon="add"
                    type="primary"
                    (click)="navigateToQualificationCreatePage()"
                >
                </esa-material-button>
            }
        </div>
    </div>
    <div class="table-responsive">
        <talentbridge-grid
            #qualificationsGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="qualifications"
            recordNamePlural="Qualifications"
            (gridReady)="onQualificationsGridReady($event)"
        >
        </talentbridge-grid>
    </div>
</div>
