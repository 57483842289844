import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { EmployeeDto } from '../shared/generated/model/employee-dto';
import { EmployeeUpsertDto } from '../shared/generated/model/employee-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';

@Injectable({
    providedIn: 'root'
})
export class CurrentEmployeeService {
    currentEmployee$ = new BehaviorSubject<EmployeeDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentEmployee(employee: EmployeeDto): void {
        this.currentEmployee$.next(employee);
    }

    getCurrentEmployee(): Observable<EmployeeDto> {
        return this.currentEmployee$.asObservable();
    }

    createEmployeeDto(employeeDto: EmployeeDto) {
        const employeeUpsertDto = new EmployeeUpsertDto({
            ZipCode: employeeDto?.ZipCode,
            Bio: employeeDto?.Bio
        });
        return employeeUpsertDto;
    }

    canEditCurrentEmployee(currentUser: UserDto, employee: EmployeeDto): boolean {
        return currentUser.UserID === employee.UserID || currentUser.UserID === employee.Manager?.UserID || this.authenticationService.hasPermission(currentUser, PermissionEnum.EmployeeRights, RightsEnum.Update);
    }
}
