import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentEmployeeService } from "src/app/services/current-employee.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { EmployeeFormComponent } from "../../shared/employee-form/employee-form.component";
import { EmployeeDto } from "src/app/shared/generated/model/employee-dto";
import { EmployeeUpsertDto } from "src/app/shared/generated/model/employee-upsert-dto";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { AsyncPipe } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: "talentbridge-employee-detail-summary",
    templateUrl: "./employee-detail-summary.component.html",
    styleUrls: ["./employee-detail-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        EsaMaterialButtonComponent,
        EmployeeFormComponent,
        AsyncPipe,
        MatIconModule,
    ],
})
export class EmployeeDetailSummaryComponent implements OnInit, OnDestroy {
    @ViewChild("updateEmployeeForm", { read: EmployeeFormComponent })
    form: EmployeeFormComponent;

    public employee$: Observable<any>;
    public currentUser: UserDto;
    public editMode = false;
    public originalEmployeeUpsertDto: EmployeeUpsertDto;

    currentUserSubscription: Subscription;

    constructor(
        private currentEmployeeService: CurrentEmployeeService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.cdr.markForCheck();
            });

        this.employee$ = this.currentEmployeeService.getCurrentEmployee().pipe(
            map((data) => {
                this.originalEmployeeUpsertDto =
                    this.currentEmployeeService.createEmployeeDto(data);
                this.cdr.markForCheck();
                return data;
            }),
        );
    }

    ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
    }

    refreshEmployee(result: any) {
        this.editMode = false;
        this.currentEmployeeService.setCurrentEmployee(result);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save(result) {
        this.refreshEmployee(result);
    }

    canEdit(employee: EmployeeDto): boolean {
        return (
            this.currentEmployeeService.canEditCurrentEmployee(
                this?.currentUser,
                employee,
            ) && !this.editMode
        );
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return (
                JSON.stringify(this.originalEmployeeUpsertDto) ===
                JSON.stringify(this.form.employeeUpsertDto)
            );
        } else {
            return true;
        }
    }
}
