<div class="list-view">
    <div class="page-header">
        <h1 class="page-title">Clients</h1>
    </div>
    <div class="table-responsive">
        <talentbridge-grid
            #ClientsGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="clients"
            recordNamePlural="Clients"
            [pagination]="true"
            [sizeColumnsToFitGrid]="true"
            (gridReady)="onClientsGridReady($event)">
        </talentbridge-grid>
    </div>
</div>
