@if (qualification$ | async; as qual) {
    <div class="detail-view">
        <div class="page-header has-actions-bar">
            <h1 class="page-title">Summary</h1>
            <div class="actions-bar">
                @if (canEdit(qual)) {
                    <esa-material-button
                        class="secondary-outline-button"
                        icon="edit"
                        label="Edit"
                        (click)="enableEditMode()"
                    >
                    </esa-material-button>
                }
            </div>
        </div>

        <talentbridge-qualification-form
            #updateQualificationForm
            [qualificationID]="qual.QualificationID"
            [qualification]="qual"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"
        >
        </talentbridge-qualification-form>
    </div>
}
