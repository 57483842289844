import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    QueryList,
    ViewChildren
} from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { ColDef, GridApi, SelectionChangedEvent } from "ag-grid-community";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import * as L from 'leaflet';
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { MapMarkerComponent } from 'src/app/shared/components/map/map-marker/map-marker.component';
import { MapComponent, MapInitEvent } from 'src/app/shared/components/map/map.component';
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { ProjectSummaryDto } from "src/app/shared/generated/model/project-summary-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";

@Component({
    selector: "talentbridge-project-list",
    templateUrl: "./project-list.component.html",
    styleUrls: ["./project-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        EsaMaterialButtonComponent,
        TalentBridgeGridComponent, 
        MatIconModule,
        MapComponent,
        MapMarkerComponent,
        MatExpansionModule
    ],
})
export class ProjectListComponent implements OnInit {
    @ViewChildren('mapMarker') mapMarkers:  QueryList<MapMarkerComponent>;

    public currentUser: UserDto;
    public projects: ProjectSummaryDto[];
    public projectsWithGeoData: ProjectSummaryDto[];
    public filteredProjectsWithGeoData: ProjectSummaryDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    gridApi: GridApi;

    user: Subscription;
    getProjectRequest: Subscription;

    constructor(
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {
        this.columnDefs = [
            {
                headerName: "D Number",
                headerTooltip: "D Number",
                valueGetter: function (params: any) {
                    return {
                            LinkValue: params.data.ProjectID,
                            LinkDisplay: params.data.DNumber,
                        };
                },
                cellRendererSelector: (params) => {
                    return {
                            component: LinkRendererComponent,
                            params: { inRouterLink: "/projects/" },
                        };
                },
                filterValueGetter: function (params: any) {
                    return params.data.DNumber;
                },
                comparator: function (
                    linkA,
                    linkB,
                    nodeA,
                    nodeB,
                    isDescending
                ) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 2,
                sort: "asc",
                tooltipField: "DNumber",
            },
            {
                headerName: "Project Name",
                field: "Name",
                flex: 2,
                tooltipField: "Name",
            },
            {
                headerName: "Client Name",
                field: "ClientName",
                flex: 2,
                tooltipField: "ClientName",
            },
            {
                headerName: "Project Manager",
                field: "ProjectManager",
                flex: 2,
                tooltipField: "ProjectManager",
            },
            {
                headerName: "Status",
                field: "Status",
                flex: 1,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Status",
                },
                tooltipField: "Status",
            },
            {
                headerName: "Location",
                field: "Location",
                flex: 2,
                tooltipField: "Location",
            },
            {
                headerName: "Map",
                field: "HasGeoSpatialData",
                cellRenderer: (params) => {
                    return params.value ? "<mat-icon class='mat-icon material-icons mat-icon-no-color'>location_on</mat-icon>" : "<mat-icon class='mat-icon material-icons mat-icon-no-color'>location_off</mat-icon>";
                },
                flex: 1,
            },
            this.dateColumnCreator.createDateColumnDef(
                "Start Date",
                "StartDate",
                "M/dd/YYYY"
            ),
            this.dateColumnCreator.createDateColumnDef(
                "End Date",
                "EndDate",
                "M/dd/YYYY"
            ),
        ];
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.getProjectRequest.unsubscribe();
    }

    navigateToProjectCreatePage() {
        this.router.navigate(["/projects/create"]);
    }

    onProjectsGridReady(gridEvent) {
        this.gridApi = gridEvent.api;
        this.gridApi.showLoadingOverlay();

        this.getProjectRequest = this.projectService
            .projectsGet()
            .subscribe((results) => {
                this.rowData = results;

                this.gridApi.hideOverlay();
                this.projects = results;
                this.projectsWithGeoData = results.filter((p) => p.HasGeoSpatialData);
                this.filteredProjectsWithGeoData = this.projectsWithGeoData;
                this.cdr.markForCheck();
            });
    }

    onFilterChanged(gridEvent) {
        this.filteredProjectsWithGeoData = gridEvent.api.rowModel.rowsToDisplay.map((r) => r.data).filter((p) => p.HasGeoSpatialData)
    }

    onSelectionChanged($event: SelectionChangedEvent<any,any>) {
        const selectedRows = $event.api.getSelectedRows();
        const selectedRow = selectedRows[0];
        // swap the related map marker so it looks highlighted
        this.mapMarkers.forEach(marker => {
            if (marker.latitude === selectedRow.Latitude && marker.longitude === selectedRow.Longitude) {
                marker.marker._icon.classList.add('highlight');
            } else {
                marker.marker._icon.classList.remove('highlight');
            }
        });
        if (selectedRow.HasGeoSpatialData) {
            this.map.flyTo(new L.LatLng(selectedRow.Latitude, selectedRow.Longitude), 12);
        }
    }

    get canCreateProject(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }

    public map : L.Map;
    public layerControl: L.Control.Layers;
  
    mapInit(mapInitEvent: MapInitEvent) {
      this.map = mapInitEvent.map;
      this.layerControl = mapInitEvent.layerControl;
      this.cdr.markForCheck();
    }

    onMarkerClick($event: Event, obj: any) {
        this.goToSelected(obj.ProjectID);
    }
    
    goToSelected(projectID) {
        this.gridApi.forEachNode((node) => {
            if (node.data.ProjectID === projectID) {
                node.setSelected(true, true);
                this.gridApi.ensureNodeVisible(node, 'top');
                this.gridApi.setFocusedCell(node.rowIndex, "DNumber", 'top');
                
                // this allows the grid rows to expand and then scrolls to the row again.

            }
        });
    }
}
