import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from "@angular/core";
import { Router } from "@angular/router";
import { ColDef } from "ag-grid-community";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";
import { SubServiceService } from "src/app/shared/generated/api/sub-service.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";


@Component({
    selector: "talentbridge-sub-service-list",
    templateUrl: "./sub-service-list.component.html",
    styleUrls: ["./sub-service-list.component.scss"],
    standalone: true,
    imports: [
        EsaMaterialButtonComponent,
        TalentBridgeGridComponent
    ]
})
export class SubServiceListComponent implements OnInit, OnDestroy {
    private currentUser: UserDto;
    public canCreate: boolean;
    public rowData = [];

    columnDefs: ColDef[];
    userSubscription: Subscription;
    subServiceSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private subServiceService: SubServiceService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;
                this.canCreate = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.QualificationRights, RightsEnum.Create);

                this.createColumnDefs();
                this.cdr.markForCheck();
            });
    }

    createColumnDefs() {
        this.columnDefs = [
            {
                headerName: "Service Line",
                field: "ServiceCategory.ServiceLine.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ServiceCategory.ServiceLine.Name",
                },
                tooltipField: "ServiceCategory.ServiceLine.Name",
            },
            {
                headerName: "Service Category",
                field: "ServiceCategory.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ServiceCategory.Name",
                },
                tooltipField: "ServiceCategory.Name",
            },
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.SubServiceID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: (params) => {
                    return {
                        component: LinkRendererComponent,
                        params: { inRouterLink: "./" },
                    };
                },
                filterValueGetter: function (params: any) {
                    return params.data.Name;
                },
                comparator: function (linkA: any, linkB: any) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 3,
                tooltipField: "$NameAndDescription",
                wrapText: true,
            },
        ];
    }

    onSubServicesGridReady(gridEvent) {
        gridEvent.api.showLoadingOverlay();
        this.subServiceSubscription = this.subServiceService
        .subServicesGet()
        .subscribe((subServices) => {
            this.rowData = subServices.map((sub) => {
                sub["$NameAndDescription"] = sub.Description ? `${sub.Name}: ${sub.Description}` : sub.Name;
                return sub;
            });
            
            gridEvent.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    navigateToSubServiceCreatePage() {
        this.router.navigateByUrl("sub-services/create");
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.subServiceSubscription?.unsubscribe();
    }
}
